<template>
  <div class="order">
    <div class="order-title">
      {{ $t('views.order.title', { id: getOrder.id }) }}
    </div>
    <div
      v-for="(creditOffered, index) in getOrder.credit_offereds"
      :key="index"
      class="order-content"
    >
      <card-info
        :item="creditOffered"
      />
      <div class="d-flex flex-column justify-space-between">
        <p class="total-value">
          {{ $t('views.order.total_value',
                {
                  material_type: creditOffered.material_type.toLowerCase(),
                  total_value: formatMoney(creditOffered.total_value_with_aditional)
                })
          }}
        </p>

        <p
          v-if="orderIsPending"
          class="font-weight-black"
        >
          <a
            :href="paymentLink"
            target="_blank"
          >
            {{ $t('views.order.payment_link') }}
          </a>
        </p>
      </div>
    </div>
    <div
      v-if="orderIsPaid"
      class="certify-info"
    >
      <rectoplus-button
        class="certify-button"
        :text="$t('views.order.show_certificate')"
        @click="showCertify"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import formatMoney from '@/mixins/formatMoney';
import CardInfo from '@/components/cards/offered/CardInfo.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'Order',

  components: {
    CardInfo,
    RectoplusButton,
  },

  mixins: [formatMoney],

  computed: {
    ...mapGetters([
      'getOrder',
      'getBusiness',
      'orderIsPending',
      'orderIsPaid',
    ]),

    paymentLink() {
      return this.getOrder?.payment?.link_to_payment;
    },
  },
  mounted() {
    this.showOrder({ business_id: this.getBusiness.id, id: this.$route.params.id });
  },
  methods: {
    ...mapActions(['showOrder']),

    showCertify() {
      window.open(this.getOrder.certify.url);
    },
  },

};
</script>
<style lang="scss" scoped>
  .order {
    background-color: $app_background;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 2em 2em 2em 16em;

    .order-title {
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: 40px;
      text-align: center;
    }

    .order-content{
      margin-bottom: 30px;
      background-color: $color_light;
      padding: 30px;
      box-shadow: $box_shadow_my_offereds;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;

      .total-value {
        font-size: 1em;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
      }
    }

    .certify-info {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;

      .certify-button {
        width: 300px;
        height: 80px;
      }
    }
  }
</style>
